body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

.view {
    width: 100%;
}

.tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.tabs > button {
    border: 1px solid transparent;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 8px 16px;
    outline: none;
    cursor: pointer;
    background-color: #ededed;
}

.tabs > button.selected {
    border: 1px solid cyan;
    border-bottom-color: lightcyan;
    background-color: lightcyan;
}

.content {
    border: 1px solid cyan;
    background-color: lightcyan;
    width: 100%;
    min-height: 2em;
    padding: 8px 16px;
}
